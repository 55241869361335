import './App.css';

function App() {
  return (
    <div className="App">
      <img src="https://thegrio.com/wp-content/uploads/2022/05/Untitled-design-2022-05-09T152553.873.png" alt="DeSantis, Trump, and McConnell"/>
    </div>
  );
}

export default App;
